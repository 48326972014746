<template>
  <div>
    <div class="head">
      <img class="avatar" :src="iconImg" />
    </div>
    <div class="activate" v-loading.fullscreen="loading">
      <div class="box">
        <div class="head">
          {{ $t("btn.aa") }}
        </div>
        <div class="content">
          <div
            style="
              white-space: 100%;
              height: 100%;
              padding: 30px;
              box-sizing: border-box;
            "
          >
            <div
              style="
                display: flex;
                align-items: center;
                font-size: 14px;
                justify-content: center;
              "
            >
              <span style="white-space: nowrap"
                >{{ $t("ifm.icn") }}/{{ $t("ifm.vin") }}：</span
              >
              <el-input
                v-model="code"
                style="width: 200px"
                :placeholder="$t('tips.sr')"
              ></el-input>
            </div>
            <div style="text-align: center; margin-top: 30px">
              <el-button
                color="#049eff"
                type="primary"
                style="padding: 5px 30px; border-radius: 15px; font-size: 14px"
                @click="activate"
              >
                {{ $t("btn.activate") }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <el-dialog v-model="isShow" align-center>
        <div style="text-align: center; font-weight: bolder; font-size: 20px">
          {{ $t("ifm.ave") }}
        </div>
        <div
          style="
            text-align: center;
            font-size: 14px;
            color: gray;
            margin-top: 20px;
          "
        >
          {{ $t("ifm.pcy") }}
        </div>
        <div
          slot="footer"
          style="
            width: 100%;
            background-color: #049eff;
            color: #fff;
            padding: 10px 0;
            text-align: center;
          "
          @click="goLogin"
        >
          {{ $t("btn.close") }}
        </div>
      </el-dialog>
      <el-dialog v-model="isShowA" align-center>
        <div style="text-align: center; font-weight: bolder; font-size: 20px">
          Information not found. Please visit the link below to update your
          vessel information.
        </div>
        <div
          style="
            text-align: center;
            font-size: 14px;
            color: gray;
            margin-top: 20px;
          "
        >
          <el-link type="primary" :underline="false"
            >https://IHSSomething.com</el-link
          >
        </div>
        <div
          slot="footer"
          style="
            width: 100%;
            background-color: #049eff;
            color: #fff;
            padding: 10px 0;
            text-align: center;
          "
          @click="goLogin"
        >
          {{ $t("btn.close") }}
        </div>
      </el-dialog>
      <el-dialog v-model="isShowB" align-center>
        <div style="text-align: center; font-weight: bolder; font-size: 20px">
          Account has already been activated.
        </div>
        <div
          style="
            text-align: center;
            font-size: 14px;
            color: gray;
            margin-top: 20px;
          "
        >
          you may login to the system.
        </div>
        <div
          slot="footer"
          style="
            width: 100%;
            background-color: #049eff;
            color: #fff;
            padding: 10px 0;
            text-align: center;
          "
          @click="goLogin"
        >
          {{ $t("btn.close") }}
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { goActivate } from "@/services/user";
import iconImg from "@/assets/icon.png";
export default {
  data() {
    return {
      loading: false,
      isShow: false,
      isShowA: false,
      isShowB: false,
      code: "",
      iconImg,
    };
  },
  methods: {
    goLogin() {
      this.$router.push("/login");
    },
    activate() {
      this.loading = true;
      goActivate(this.code).then((res) => {
        if (res.code == 200) {
          if (res.data == 1) {
            this.isShowB = true;
          }
          if (res.data == 2) {
            this.isShowA = true;
          }
          if (res.data == 3) {
            this.isShow = true;
          }
          this.loading = false;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.activate {
  width: 100%;
  height: calc(100vh - 100px);
  background-color: #f4f6fa;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  width: 500px;
  background-color: #fff;
  align-items: start;
  border-radius: 10px;
  .head {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    font-weight: bolder;
    color: gray;
    border: none;
  }
}

.head {
  width: 100%;
  height: 100px;
  padding: 0 35px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
}
.content {
  border-top: 1px solid rgba(33, 33, 33, 0.2);
}

::v-deep.el-dialog__footer {
  padding: 0 !important;
}

::v-deep .el-dialog__footer:hover {
  cursor: pointer;
}
.avatar {
  width: 70px;
  height: 70px;
}
</style>